var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"owner-create-modal","title":"店舗のカテゴリーを追加","centered":"","hide-footer":"","no-close-on-backdrop":true,"no-close-on-esc":true,"hide-header-close":true},on:{"show":_vm.onShowModal}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createCategory)}}},[_c('ValidationProvider',{attrs:{"rules":{ nameRequired: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"label":"氏名","placeholder":"氏名を入力","isValid":!validated ? null : invalid ? false : true},scopedSlots:_vm._u([(errors[0])?{key:"invalid-feedback",fn:function(){return [_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]},proxy:true}:null],null,true),model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ emailRequired: true, emailFormat: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"label":"メールアドレス","placeholder":"メールアドレスを入力","type":"email","isValid":!validated ? null : invalid ? false : true},scopedSlots:_vm._u([(errors[0])?{key:"invalid-feedback",fn:function(){return [_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]},proxy:true}:null],null,true),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),(_vm.errorMessage && _vm.errorMessage !== '')?_c('div',{staticClass:"text-center text-danger mb-2"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('CButton',{staticClass:"mx-2",attrs:{"variant":"outline","color":"primary","type":"submit"}},[_vm._v("提出")]),_c('CButton',{staticClass:"mx-2",attrs:{"variant":"outline","color":"dark","type":"button"},on:{"click":function($event){return _vm.$bvModal.hide('owner-create-modal')}}},[_vm._v("キャンセル ")])],1)],1)]}}])}),(_vm.loading)?_c('CElementCover',{attrs:{"opacity":0.8}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }