<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CRow>
          <CCol class="d-flex align-items-center">
            <CIcon :content="titleIcon" class="mr-2" />
            {{ caption }}
          </CCol>
          <CCol class="d-flex align-items-center justify-content-end">
            <CButton
              color="success"
              square
              size="sm"
              v-b-modal.owner-create-modal
              >店舗のカテゴリーを追加
            </CButton>
          </CCol>
        </CRow>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :table-filter="{ label: 'フィルター' }"
          :items-per-page-select="{label: '表示件数' }"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :dark="dark"
        sorter
        pagination
      >
        <template #action="{ item }">
          <td>
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              class="mx-1"
              v-b-modal.owner-edit-modal
              @click="ownerId = item.uuid"
              >Edit
            </CButton>
            <CButton
                v-if="!item.shop"
                color="danger"
                variant="outline"
                square
                size="sm"
                class="mx-1"
                @click="deleteShopOwner(item.uuid)"
            >Delete
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>

    <OwnerCreateModal />
    <OwnerEditModal :currentShopId="ownerId" />
  </CCard>
</template>

<script>
import OwnerCreateModal from "./OwnerCreateModal";
import OwnerEditModal from "./OwnerEditModal.vue";

export default {
  name: "OwnersTable",
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
  },
  components: {
    OwnerCreateModal,
    OwnerEditModal,
  },
  data() {
    return {
      ownerId: "",

      titleIcon: [
        "512 512",
        "<path fill='var(--ci-primary-color, currentColor)' d='M88,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,160a64,64,0,1,0-64-64A64.072,64.072,0,0,0,424,160Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,424,64Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,448Z' class='ci-primary'/>",
      ],
    };
  },
  methods: {
    deleteShopOwner(id) {
      this.$swal
        .fire({
          title: "Delete Shop Owner",
          text: "Do you really want to delete selected shop owner?",
          icon: "info",
          showCancelButton: true,
          showConfirmButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              canCancel: false,
            });

            this.$store
              .dispatch("shopOwner/deleteShowOwner", id)
              .then((res) => {
                let { success } = res.data;

                if (success) {
                  this.$swal.fire({
                    title: "Delete Shop Owner",
                    text: "Delete Shop Owner Successfully!",
                    icon: "success",
                    willClose: () => {
                      this.$store.dispatch("shopOwner/getOwnerList");
                    },
                  });
                } else {
                  this.$swal.fire({
                    title: "Delete Shop Owner",
                    text: "Delete Shop Owner Failed!",
                    icon: "error",
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: "Delete Shop Owner",
                  text: "Delete Shop Owner Failed!",
                  icon: "error",
                });
              })
              .finally(() => {
                loader.hide();
              });
          }
        });
    },
  },
};
</script>
