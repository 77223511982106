<template>
  <b-modal
    id="owner-create-modal"
    title="店舗のカテゴリーを追加"
    centered
    hide-footer
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :hide-header-close="true"
    @show="onShowModal"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <CForm role="form" @submit.prevent="handleSubmit(createCategory)">
        <ValidationProvider
          :rules="{ nameRequired: true }"
          v-slot="{ errors, invalid, validated }"
        >
          <CInput
            label="氏名"
            placeholder="氏名を入力"
            v-model="name"
            :isValid="!validated ? null : invalid ? false : true"
          >
            <template #invalid-feedback v-if="errors[0]">
              <div class="invalid-feedback">{{ errors[0] }}</div>
            </template>
          </CInput>
        </ValidationProvider>
        <ValidationProvider
          :rules="{ emailRequired: true, emailFormat: true }"
          v-slot="{ errors, invalid, validated }"
        >
          <CInput
            label="メールアドレス"
            placeholder="メールアドレスを入力"
            type="email"
            v-model="email"
            :isValid="!validated ? null : invalid ? false : true"
          >
            <template #invalid-feedback v-if="errors[0]">
              <div class="invalid-feedback">{{ errors[0] }}</div>
            </template>
          </CInput>
        </ValidationProvider>
        <div
          v-if="errorMessage && errorMessage !== ''"
          class="text-center text-danger mb-2"
        >
          {{ errorMessage }}
        </div>
        <div class="text-center">
          <CButton variant="outline" color="primary" type="submit" class="mx-2"
            >提出</CButton
          >
          <CButton
            variant="outline"
            color="dark"
            type="button"
            class="mx-2"
            @click="$bvModal.hide('owner-create-modal')"
            >キャンセル
          </CButton>
        </div>
      </CForm>
    </ValidationObserver>
    <CElementCover :opacity="0.8" v-if="loading" />
  </b-modal>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { email as emailRule, required } from "vee-validate/dist/rules";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {},

  data() {
    return {
      name: "",
      email: "",

      errorMessage: "",
      loading: false,
    };
  },

  methods: {
    onShowModal() {
      this.resetForm();
    },

    resetForm() {
      this.name = "";
      this.email = "";
      this.errorMessage = "";
    },

    createCategory() {
      this.loading = true;

      this.$store
        .dispatch("shopOwner/createOwner", {
          name: this.name,
          email: this.email,
        })
        .then((res) => {
          let { success } = res.data;

          if (success) {
            this.resetForm();

            this.$bvModal.hide("owner-create-modal");
            this.$store.dispatch("shopOwner/getOwnerList");
          } else {
            this.errorMessage = "Create Shop Owner Failed!";
          }
        })
        .catch((err) => {
          let response = err.response;

          if (response.status !== 422) {
            this.errorMessage = "Create Shop Owner Failed";
          } else {
            let errors = response.data.errors;
            let errorKey = Object.keys(errors)[0];

            this.errorMessage = errors[errorKey][0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    extend("nameRequired", {
      ...required,
      message: () => "氏名を入力してください",
    });

    extend("emailRequired", {
      ...required,
      message: () => "メールアドレスを入力してください",
    });

    extend("emailFormat", {
      ...emailRule,
      message: () => "Owner email is wrong",
    });
  },
};
</script>
