<template>
  <div>
    <CRow ref="shopOwnersSection">
      <CCol col="12">
        <OwnerTable
          :items="ownerList"
          :fields="tableFields"
          hover
          striped
          border
          small
          fixed
          caption="オーナーリスト"
        />
      </CCol>

      <CElementCover :opacity="0.8" v-if="tableLoading" />
    </CRow>
  </div>
</template>

<script>
import {mapState} from "vuex";
import OwnerTable from "@/components/shop_owners/OwnersTable";

export default {
  components: {
    OwnerTable,
  },

  computed: {
    ...mapState({
      tableLoading: (state) => state.shopOwner.tableLoading,
      ownerList: (state) => state.shopOwner.ownerList,
    }),
  },

  data() {
    return {
      tableFields: [
        {
          key: "uuid",
          label: "ID",
        },
        {
          key: "name",
          label: "氏名",
        },
        {
          key: "email",
          label: "メールアドレス",
        },
        {
          key: "action",
          label: "編集",
        },
      ],
    };
  },

  mounted() {
    this.$store.dispatch("shopOwner/getOwnerList");
  },

  metaInfo: {
    title: "Shop Owners Management",
  },
};
</script>
